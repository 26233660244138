<template>
  <div class="mainContainer" style="padding: 5px 10px 10px 10px">
    <AddCategory :getCategory="getCategory" />
    <div v-if="!isLoading" class="testingScroll">
      <table>
        <tr>
          <th>{{ $t("admin.active") }}</th>
          <th>{{ $t("admin.title") }}</th>
          <th>{{ $t("admin.edit") }}</th>
          <th>{{ $t("admin.delete") }}</th>
        </tr>

        <tr v-for="category in allCategory" :key="category.id">
          <td class="show_on_front">
            <i v-if="category.show == true" class="fal fa-check-circle"></i>
            <i v-if="category.show == false" class="fal fa-ban"></i>
          </td>
          <td>
            <div v-if="lang=='ka'" v-html="category.titleKA"></div>
            <div v-else v-html="category.titleEN"></div>
          </td>
          <td>
            <b-button
              @click="
                (modalShow = !modalShow),
                  editCategory(
                    category._id,
                    category.show,
                    category.titleKA,
                    category.titleEN
                  )
              "
            >
              <i class="edit fal fa-pen"></i>
            </b-button>
          </td>
          <td>
            <ConfirmDelete @clicktodelete="deleteCategory(category._id)" />
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="8"
        :columns="2"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>

    <b-modal id="modal-lg-2" v-model="modalShow" size="lg" hide-footer>
      <div>
        <label for="">{{ $t("admin.title") }} (KA)</label>
        <input class="input" type="text" v-model="update.titleKA" />
      </div>
      <div>
        <label for="">{{ $t("admin.title") }} (EN)</label>
        <input class="input" type="text" v-model="update.titleEN" />
      </div>
      <div>
        <label for="">{{ $t("admin.activate_on_front") }}</label>
      </div>
      <input class="checkbox" type="checkbox" v-model="update.show" />
      <button
        type="submit"
        class="submit btn btn-primary"
        @click="UpdateCategory()"
      >
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import spinner from "../../spinner/Spinner.vue";
import ConfirmDelete from "../../../components/Confirm/DeleteConfirm.vue";
import AddCategory from "../AddComponents/AddCategory.vue";
import Swal from "sweetalert2";

export default {
  name: "getCategory",
  components: {
    AddCategory,
    spinner,
    ConfirmDelete,
  },
  data() {
    return {
      modalShow: false,
      allCategory: [],
      checkedItems: [],
      active_id: "",
      update: {
        show: false,
        titleKA: "",
        titleEN: "",
      },
      isLoading: false,
      lang: "ka",
    };
  },
  mounted() {
    this.getCategory();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    getCategory() {
      this.isLoading = true;
      axios.get(`${env.host}/get/all/categories/admin`).then((res) => {
        this.isLoading = false;
        this.allCategory = res.data.item;
      });
    },
    editCategory(id, show, titleKA, titleEN) {
      this.active_id = id;
      this.update.show = show;
      this.update.titleKA = titleKA;
      this.update.titleEN = titleEN;
    },
    UpdateCategory() {
      this.isLoading = true;
      axios
        .post(`${env.host}/edit/category/${this.active_id}`, this.update)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getCategory();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    deleteCategory(id) {
      this.isLoading = true;
      axios.delete(`${env.host}/delete/category/${id}`).then(() => {
        Swal.fire({
          icon: "success",
          text: this.$t("success.success_delete"),
        });
        this.isLoading = false;
        this.getCategory();
      });
    },
  },
};
</script>

<style scoped>
.mainContainer {
  width: 100%;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}
.testingScroll {
  width: 100% !important;
  min-height: calc(100vh - 240px);

  max-height: calc(100vh - 150px) !important;
  overflow-y: scroll;
}

label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
.show_on_front {
  font-size: 30px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
.delete {
  background: #f02849;
  border-radius: 0px !important;
}
.delete i,
.edit {
  color: white;
}
table {
  width: 100% !important;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}

td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}
th,
td {
  padding: 1em;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
.input {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  padding-left: 10px;
  height: 40px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
