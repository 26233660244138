<template>
  <div class="category">
    <b-button @click="modalShow = !modalShow">{{
      $t("add_category.add_category")
    }}</b-button>
    <b-modal id="modal-lg" v-model="modalShow" size="lg" hide-footer>
      <form @submit.prevent="AddCategory">
        <div>
          <label for="">{{ $t("admin.title") }} (KA)</label>
          <input class="input" type="text" v-model="form.titleKA" />
        </div>
        <div>
          <label for="">{{ $t("admin.title") }} (EN)</label>
          <input class="input" type="text" v-model="form.titleEN" />
        </div>
        <div>
          <label for="">{{ $t("admin.activate_on_front") }}</label>
        </div>
        <input class="checkbox" type="checkbox" v-model="form.show" />
        <button type="submit" class="submit btn btn-primary">
          <span>{{ $t("admin.add") }}</span>
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import Swal from "sweetalert2";

export default {
  name: "login",
  data() {
    return {
      modalShow: false,
      form: {
        show: false,
        titleEN: "",
        titleKA: "",
      },
    };
  },
  props: {
    getCategory: { type: Function },
  },
  methods: {
    AddCategory() {
      this.isLoading = true;
      axios
        .post(`${env.host}/add/category`, this.form)
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_add"),
          });
          this.isLoading = false;
          this.modalShow = false;
          this.emptyValues();
          setTimeout(() => {
            this.getCategory();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    emptyValues() {
      this.form.show = false;
      this.form.titleEN = "";
      this.form.titleKA = "";
    },
  },
};
</script>

<style scoped>
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.input {
  border-radius: 0px;

  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  height: 40px;
  padding-left: 10px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
.category {
  margin-bottom: 10px;
}
.text_editor {
  margin-bottom: 50px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
